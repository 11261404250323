import React, { useState, useEffect } from 'react';
import Sidebar from 'components/ui-base/Sidebar';
import Badge from 'components/ui-components-v2/Badge';
import Icon from 'components/ui-components-v2/Icon';
import Columns from 'components/ui-base/Columns';
import EditorData from 'components/editor-data/EditorData';
import CreativeBuilderTypeIcon from 'components/creative-builder/CreativeBuilderTypeIcon';
import CreativeBuilderHelpers from 'components/creative-builder/CreativeBuilderHelpers';
import CreativeBuilderBlock from 'components/creative-builder/CreativeBuilderBlock';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { CommentsHelpers } from 'helpers/comments.helpers';

import '../styles/main.scss';

const COMMENTS_ROOT_MODEL = 'creativeBuilder';

/**
 * Helper function to create the list of items
 */
const getList = (value, dataModel, partial, partialList, creativeBuilderComments) => {
    const fullList = [];

    if (value && Array.isArray(value) && value.length) {
        value.forEach((item) => {
            const data = EditorData.getValueFromModel(dataModel + '.' + item.uuid);
            if (!data) return;
            const commentsNumber = creativeBuilderComments?.[item.uuid]?.length;
            fullList.push({
                ...item,
                id: item.uuid,
                title: data.title ? data.title : item.identifier,
                typeIcon: <CreativeBuilderTypeIcon type={CreativeBuilderHelpers.getTypeIcon(data)} className="sidebar-list-item__type--usefilter" />,
                endIcon: commentsNumber ? (
                    <Badge color="primary" badgeContent={commentsNumber}>
                        <Icon fontSize="small">message</Icon>
                    </Badge>
                ) : undefined
            });
        });
    }

    return partial && partialList && partialList.length ? fullList.filter((item) => partialList.includes(item.id)) : fullList;
};

/**
 * Creative Builder Preview
 * Shows all the items as a preview
 */
const CreativeBuilderPreview = ({
    value = [],
    displayType = 'list',
    dataModel,
    partial,
    partialList,
    onSetActiveTitle,
    model,
    language,
    defaultFullSize,
    isSocial
}) => {
    const [list, setList] = useState(getList(value, dataModel, partial, partialList));
    const [currentItem, setCurrentItem] = useState(list.length ? list[0] : {});

    const { creativeBuilderComments } = useComponentStore('Comments', {
        fields: {
            creativeBuilderComments: COMMENTS_ROOT_MODEL
        }
    });

    useEffect(() => {
        /**
         * Fetch comments for all items and store them in Redux.
         */
        const campaignId = EditorData.getId();
        if (value && value.length) {
            value.forEach((item) => {
                CommentsHelpers.fetchComments(`${COMMENTS_ROOT_MODEL}.${item.uuid}`, campaignId, undefined, true);
            });
        }
        return () => {
            CommentsHelpers.cleanUpComments(COMMENTS_ROOT_MODEL);
        };
    }, [value]);

    // Build the list of items
    useEffect(() => {
        const filteredList = getList(value, dataModel, partial, partialList, creativeBuilderComments);
        setList(filteredList);
    }, [value, dataModel, partial, partialList, creativeBuilderComments]);

    // Handle selecting an item
    const handleSelectItem = (uuid) => {
        const selectedItem = value.find((item) => item.uuid === uuid);
        const currentTitle = EditorData.getValueFromModel(dataModel + '.' + selectedItem.uuid + '.title');
        setCurrentItem(selectedItem);
        onSetActiveTitle(currentTitle);
    };

    if (!list.length) return <div>No creatives found</div>;

    return (
        <div className="creative-builder-preview">
            {displayType === 'list' ? (
                <Sidebar list={list} activeItem={currentItem && currentItem.uuid} onSelect={handleSelectItem} docked>
                    <CreativeBuilderBlock
                        key={'creative-builder-item__' + currentItem.uuid}
                        data={EditorData.getValueFromModel(dataModel + '.' + currentItem.uuid)}
                        model={model}
                        dataModel={dataModel + '.' + currentItem.uuid}
                        language={language}
                        isSocial={isSocial}
                        defaultFullSize={defaultFullSize}
                        reviewInBox={false}
                        canReview={false}
                        item={currentItem}
                    />
                </Sidebar>
            ) : (
                <Columns count={3}>
                    {list.map((item) => (
                        <CreativeBuilderBlock
                            key={'creative-builder-item__' + item.uuid}
                            data={EditorData.getValueFromModel(dataModel + '.' + item.uuid)}
                            model={model}
                            dataModel={dataModel + '.' + item.uuid}
                            language={language}
                            isSocial={isSocial}
                            defaultFullSize={defaultFullSize}
                            reviewInBox={false}
                            canReview={false}
                            item={item}
                        />
                    ))}
                </Columns>
            )}
        </div>
    );
};

export default CreativeBuilderPreview;
