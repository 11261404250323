import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withResources } from 'components/data/Resources';
import { withComponentStore } from 'components/data/ComponentStore';
import CreativeBuilderHelpers from 'components/creative-builder/CreativeBuilderHelpers';
import { fetchTemplates } from 'components/template-management/utilities';
import Loader from 'components/ui-components/Loader';
import Templates from 'components/data/Templates';
import ChannelItem from './channel-item';
import AssetItem from './asset-item';
import '../styles/main.scss';

/**
 * CreativeBuilderItem
 * Renders an item from the creative builder
 * This directly outputs an asset (displayAd, dynamicImage, dynamicPDF, dynamicVideo, dynamicAfterEffects or dynamicInDesign) or a channelItem.
 */
class CreativeBuilderItem extends React.Component {
    static propTypes = {
        // The full data to be pushed
        data: PropTypes.object,
        // To datamodel to use
        dataModel: PropTypes.string,
        // The current language
        language: PropTypes.string,
        // The active subset
        subsetActive: PropTypes.string,
        // If true, uses the previewformats
        isPreview: PropTypes.bool,
        // Check whether we are showing the editor
        isEditor: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = { loadingTemplates: true };
    }

    async componentDidMount() {
        const { data, loadTemplates = true } = this.props;

        /**
         * If the Creative Builder is opened via Template Designer (test mode) or Template Management, then the template data already exists in the Templates memory.
         * This is done in the topbar component of Template Designer.
         * Otherwise you would see the save data from the last time the template has saved.
         */
        if (loadTemplates) {
            await CreativeBuilderHelpers.loadTemplates(data);
            if (process.env.EXTERNAL_PREVIEW) {
                // In the external preview, we do not know wheather we should get the templates fom the new or old API.
                // So we get the templates from the new API and if they are not there, we get them from the old API.
                const templatesToLoad = CreativeBuilderHelpers.getTemplateToLoad(data);
                const templateIdentifiersToLoad = templatesToLoad.map((item) => item.identifier);
                // First we try to get all needed templates from ghe new API.
                await fetchTemplates(templateIdentifiersToLoad, true);
                const templatesNotLoaded = templatesToLoad.filter((item) => !Templates.get(item.type, item.identifier));
                if (templatesNotLoaded.length > 0) {
                    // If there are templates not loaded, we get them from the old API.
                    // The "asyncGetTemplatesList" function will check if the template is already loaded and only load the missing ones.
                    await Templates.asyncGetTemplatesList(templatesToLoad);
                }
            } else {
                // While in the regular Cape environment, "loadTemplates" can decide whether to use the old or the new API.
                await CreativeBuilderHelpers.loadTemplates(data);
            }
            this.setState({ loadingTemplates: false });
        } else {
            this.setState({ loadingTemplates: false });
        }
    }

    /**
     * Render the component
     */
    render() {
        const { loadingTemplates } = this.state;
        const { data, className, loading } = this.props;
        // Select the correct subcomponent based on the asset type and pass all props
        return (
            <div className={classNames('creative-builder-item', className)} data-tour-selector="builder-item">
                <Loader loading={loading || loadingTemplates}>
                    {data.type === 'socialChannelItem' && <ChannelItem {...this.props} />}
                    {data.type !== 'socialChannelItem' && <AssetItem {...this.props} />}
                </Loader>
            </div>
        );
    }
}

export default withComponentStore(withResources(CreativeBuilderItem, [{ resource: 'templateFormats', path: 'resources/load' }]), 'CreativeEditor', [
    'language',
    'editor'
]);
