import React from 'react';
import { cloneDeep, merge } from 'lodash';
import EditorData from 'components/editor-data/EditorData';
import { PreviewFacebook } from 'components/channels/social/PreviewFacebook';
import { PreviewInstagram } from 'components/channels/social/PreviewInstagram';
import { PreviewLinkedIn } from 'components/channels/social/PreviewLinkedIn';
import { PreviewSnapchat } from 'components/channels/social/PreviewSnapchat';
import { PreviewTwitter } from 'components/channels/social/PreviewTwitter';
import { PreviewPinterest } from 'components/channels/social/PreviewPinterest';
import { PreviewYoutube } from 'components/channels/social/PreviewYoutube';
import { PreviewTikTok } from 'components/channels/social/PreviewTikTok';
import { PreviewWeChat } from 'components/channels/social/PreviewWeChat';
import { PreviewGoogleAds } from 'components/channels/social/PreviewGoogleAds';
import { PreviewDV360 } from 'components/channels/social/PreviewDV360';
import DynamicAsset from 'components/assets/DynamicAsset';
import DataHelpers from 'components/data/DataHelpers';
import Templates from 'components/data/Templates';
import DynamicVideo from 'components/channels/video/DynamicVideo';
import ComponentStore from 'components/data/ComponentStore';
import BrandSettings from 'components/data/BrandSettings';
import CreativeBuilderHelpers from '../../CreativeBuilderHelpers';
import '../styles/main.scss';

/**
 * CreativeBuilderItem
 * Renders a channel item for the creative builder.
 */
class ChannelItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Render an individual item
     */
    socialItems = () => {
        const { data, subsetActive, language, frameNr, isEditor } = this.props;
        const adType = data.channelSetup.adType;
        const channelData = this.getChannelData(adType);
        const assets = this.getAssets();
        let component;

        // Return all obects
        if (data.channelSetup.channel === 'facebook') {
            component = <PreviewFacebook {...channelData} assetComponents={assets} frameNr={frameNr} isEditor={isEditor} />;
        }
        if (data.channelSetup.channel === 'instagram') {
            component = <PreviewInstagram {...channelData} assetComponents={assets} frameNr={frameNr} />;
        }
        if (data.channelSetup.channel === 'linkedin') {
            component = <PreviewLinkedIn {...channelData} assetComponents={assets} frameNr={frameNr} />;
        }
        if (data.channelSetup.channel === 'snapchat') {
            component = <PreviewSnapchat {...channelData} assetComponents={assets} frameNr={frameNr} />;
        }
        if (data.channelSetup.channel === 'pinterest') {
            component = <PreviewPinterest {...channelData} assetComponents={assets} frameNr={frameNr} />;
        }
        if (data.channelSetup.channel === 'twitter') {
            component = <PreviewTwitter {...channelData} assetComponents={assets} frameNr={frameNr} />;
        }
        if (data.channelSetup.channel === 'youtube') {
            component = <PreviewYoutube {...channelData} assetComponents={assets} frameNr={frameNr} />;
        }
        if (data.channelSetup.channel === 'tiktok') {
            component = <PreviewTikTok {...channelData} assetComponents={assets} frameNr={frameNr} />;
        }
        if (data.channelSetup.channel === 'wechat') {
            component = <PreviewWeChat {...channelData} assetComponents={assets} frameNr={frameNr} />;
        }
        if (data.channelSetup.channel === 'googleads') {
            component = <PreviewGoogleAds {...channelData} assetComponents={assets} frameNr={frameNr} />;
        }
        if (data.channelSetup.channel === 'dv360') {
            component = <PreviewDV360 {...channelData} assetComponents={assets} frameNr={frameNr} isEditor={isEditor} />;
        }

        const resultObject = {
            component: component,
            key: 'social1_' + subsetActive + '_' + language,
            className: 'creative-builder-item__social'
        };

        return [resultObject];
    };

    /**
     * Get Channel data
     */
    getChannelData = (adType) => {
        const { data, editor, subsetActive, dataModel } = this.props;

        if (!data.channelSetup) {
            return {};
        }

        // Find the data in the active subset
        const channelData = EditorData.getValueFromModel(dataModel + '.data.' + subsetActive + '.channelData');
        const dataNew = { frameCount: data.frames, adType: adType, ...DataHelpers.clone(channelData, false) };
        ChannelItem.translateData(EditorData.getLanguage(), dataNew);

        dataNew.advertiser = BrandSettings.getAdvertiserData();
        if (!dataNew.advertiser) {
            dataNew.advertiser = DataHelpers.getValue(editor, 'previewSetup.advertiser', {});
        }
        return dataNew;
    };

    /**
     * Get asset components
     */
    getAssets = () => {
        const { subsetActive, isPreview = true, dataModel, editor, language, specificFormat } = this.props;

        const assetSetup = this.props.data.assetSetup;
        const template = assetSetup && Templates.get(assetSetup.type, assetSetup.templateIdentifier);

        // Get initial data
        const result = [];
        const dataSet = EditorData.getValueFromModel(dataModel);

        // Loop through tabs
        let subTabs = [''];
        let channelSetup = EditorData.getValueFromModel('channelSetup', dataSet);
        if (!channelSetup) {
            channelSetup = {};
        }
        if (channelSetup.settings && channelSetup.settings.multiFrame) {
            subTabs = [];
            for (let i = 1; i <= dataSet.frames; i++) {
                subTabs.push('frame' + i);
            }
        }

        // Loop through frames
        subTabs.forEach((frame) => {
            // Asset setup
            const frameAddition = frame === '' ? '' : '.frames.' + frame;
            const assetSetup = EditorData.getValueFromModel('assetSetup' + frameAddition, dataSet);
            const assetDataModel = dataModel + '.data.' + subsetActive + '.assetData' + frameAddition;
            let assetData = EditorData.getValueFromModel(assetDataModel);
            if (!assetData) {
                assetData = {};
            }

            if (!assetSetup) {
                return;
            }

            // Statics
            if (
                assetSetup.type === 'staticAsset' &&
                (assetSetup.templateIdentifier === 'video' || assetSetup.templateIdentifier === 'image' || assetSetup.templateIdentifier === 'audio')
            ) {
                const currentLoop = [];
                let image = assetData.image;
                let video = assetData.video;
                let audio = assetData.audio;
                if (assetData && assetData.multiLanguageAsset) {
                    if (assetData.imageByLanguage && assetData.imageByLanguage[language]) {
                        image = assetData.imageByLanguage[language];
                    }
                    if (assetData.videoByLanguage && assetData.videoByLanguage[language]) {
                        video = assetData.videoByLanguage[language];
                    }
                    if (assetData.audioByLanguage && assetData.audioByLanguage[language]) {
                        audio = assetData.audioByLanguage[language];
                    }
                }

                currentLoop.push(
                    <DynamicAsset
                        image={image}
                        video={video}
                        audio={audio}
                        width={DataHelpers.getValue(channelSetup, 'settings.assetSizes.display.width', 230)}
                        template={template}
                    />
                );

                // Support dynamic ads
                if (assetData.imagePortrait || assetData.imageSquare || assetData.videoPortrait || assetData.videoPortraitByLanguage) {
                    image = assetData.imagePortrait || assetData.imageSquare;
                    video = assetData.videoPortrait;
                    if (assetData && assetData.multiLanguageAsset) {
                        if (assetData.imageSquareByLanguage && assetData.imageSquareByLanguage[language]) {
                            image = assetData.imageSquareByLanguage[language];
                        }

                        if (assetData.imagePortraitByLanguage && assetData.imagePortraitByLanguage[language]) {
                            image = assetData.imagePortraitByLanguage[language];
                        }
                        if (assetData.videoPortraitByLanguage && assetData.videoPortraitByLanguage[language]) {
                            video = assetData.videoPortraitByLanguage[language];
                        }
                    }

                    const format = (() => {
                        if (assetData.imagePortrait) return 'main9x16';
                        if (assetData.imageSquare) return 'main1x1';

                        return 'main9x16';
                    })();

                    currentLoop.push(
                        <DynamicAsset
                            data={{
                                format
                            }}
                            image={image}
                            video={video}
                            audio={audio}
                            width={DataHelpers.getValue(channelSetup, 'settings.assetSizes.display.width', 230)}
                            template={template}
                        />
                    );
                }

                result.push(currentLoop);
            }
            // Dynamic image
            else if (
                assetSetup.type === 'dynamicImage' ||
                assetSetup.type === 'dynamicPDF' ||
                assetSetup.type === 'dynamicImageDesigned' ||
                assetSetup.type === 'dynamicVideoDesigned'
            ) {
                let formatsLoop = [];
                const currentLoop = [];

                // Select all the formats
                if (channelSetup.adType === 'dynamic' || channelSetup.adType === 'dynamicCarousel' || channelSetup.adType === 'nativeDisplay') {
                    formatsLoop = assetSetup.settings.formats.map((item) => (item.key ? item.key : item.format));
                }
                // Select only the selected asset
                else {
                    let format = assetData.format ? assetData.format : 'main';
                    if (specificFormat) format = specificFormat;
                    formatsLoop.push(format);
                }

                // Loop through all formats
                formatsLoop.forEach((format) => {
                    // Merge overwrites
                    let assetDataFormat = assetData;
                    if (assetData && assetData.overwrites && assetData.overwrites[format]) {
                        assetDataFormat = cloneDeep(assetData);
                        assetDataFormat = merge(assetDataFormat, assetData.overwrites[format]);
                    }

                    // Use the height and weight as set in the template (the initial width and height)
                    const formatData = CreativeBuilderHelpers.getFormatData(assetSetup.settings.formats, format);
                    let editorScaleFactor = 1;
                    if (formatData.width > 500) {
                        editorScaleFactor = 2;
                    }

                    // Designed display ad
                    let template = {};
                    let url = assetSetup.url ? assetSetup.url : assetSetup.previewUrl;
                    const displayWidth = DataHelpers.getValue(channelSetup, 'settings.assetSizes.display.width', 230);
                    const frameWidth = formatData.width;
                    let frameHeight = formatData.height;

                    if (assetSetup.type === 'dynamicImageDesigned' || assetSetup.type === 'dynamicVideoDesigned') {
                        template = Templates.get(assetSetup.type, assetSetup.templateIdentifier, 'templateExport'); // Fetch all template data
                        url = process.env.TEMPLATES_URL;
                        if (assetSetup.type === 'dynamicVideoDesigned') {
                            frameHeight = frameHeight + (formatData.width / displayWidth) * 26;
                        }
                    }

                    const positionByLanguage = DataHelpers.getValue(assetSetup, 'settings.positionByLanguage') ? true : false;
                    const uploadByLanguage = DataHelpers.getValue(assetSetup, 'settings.uploadByLanguage') ? true : false;

                    // Create component
                    currentLoop.push(
                        <DynamicAsset
                            key={format}
                            type={assetSetup.type}
                            url={url}
                            data={{
                                ...assetDataFormat,
                                editmode: !isPreview,
                                format: format,
                                language: language,
                                positionByLanguage,
                                uploadByLanguage,
                                market: editor.market,
                                editorScaleFactor: editorScaleFactor,
                                template: template,
                                frame
                            }}
                            assetUpdateModel={assetDataModel}
                            width={displayWidth}
                            frameWidth={frameWidth}
                            frameHeight={frameHeight}
                            originalWidth={formatData.width}
                            originalHeight={formatData.height}
                            template={template}
                        />
                    );
                });
                result.push(currentLoop);
            }
            // Dynamic video
            else if (assetSetup.type === 'dynamicVideo' || assetSetup.type === 'dynamicAfterEffects') {
                let formatsLoop = [];
                const currentLoop = [];

                // Select all the formats
                if (channelSetup.adType === 'dynamic' || channelSetup.adType === 'dynamicCarousel' || channelSetup.adType === 'nativeDisplay') {
                    formatsLoop = assetSetup.settings.formats.map((item) => item.key);
                }
                // Select only the selected asset
                else {
                    let format = assetData.format ? assetData.format : 'main';
                    if (specificFormat) format = specificFormat;
                    formatsLoop.push(format);
                }

                // Loop through all formats
                formatsLoop.forEach((format) => {
                    const template = Templates.get(assetSetup.type, assetSetup.templateIdentifier); // Fetch all template data
                    const lowQualityAvailable = assetSetup.settings.lowQualityAvailable ? true : false;
                    const channelWidth = DataHelpers.getValue(channelSetup, 'settings.assetSizes.display.width', 230); // Get the channel width (e.g. the frame)

                    // Get format data
                    const formatData = CreativeBuilderHelpers.getFormatData(assetSetup.settings.formats, format);

                    const formatVideoData = (() => {
                        if (assetSetup.type !== 'dynamicAfterEffects') return;
                        const assetDataModel = dataModel + '.data.' + subsetActive + '.assetData';
                        const assetData = EditorData.getValueFromModel(assetDataModel);
                        return DataHelpers.clone(assetData, false);
                    })();

                    let height = (channelWidth / formatData.width) * formatData.height;

                    // If formatData does not exist and height becomes a NaN, we take the ratio of the output width and
                    // height. This is fallback for, for example, dynamicAfterEffects templates
                    if (isNaN(height)) {
                        const outputWidth = DataHelpers.getValue(channelSetup, 'settings.assetSizes.output.width', 1080);
                        const outputHeight = DataHelpers.getValue(channelSetup, 'settings.assetSizes.output.height', 1920);
                        height = (channelWidth / outputWidth) * outputHeight;
                    }

                    // Create component
                    currentLoop.push(
                        <DynamicVideo
                            width={channelWidth}
                            height={height}
                            frameWidth={formatData.width}
                            frameHeight={formatData.height}
                            originalWidth={formatData.width}
                            originalHeight={formatData.height}
                            previewDataModel={
                                dataModel + '.data.' + subsetActive + '.assetData' + frameAddition + '.previewData.' + formatData.format + '.' + language
                            }
                            mapping={template.publishMapping}
                            comp={formatData.comp ? formatData.comp : formatData.format}
                            format={formatData.comp ? formatData.comp : formatData.format}
                            dataModel={dataModel + '.data.' + subsetActive + '.assetData' + frameAddition}
                            controls={true}
                            autoPlay={false}
                            placeholderImage={template.image}
                            lowQualityAvailable={lowQualityAvailable}
                            template={template}
                            data={formatVideoData}
                        />
                    );
                });
                result.push(currentLoop);
            }
        });

        return result;
    };

    /**
     * Translate
     * @param {string} language The language to be used
     * @param {object} data
     */
    static translateData = (language, data) => {
        if (typeof data === 'object' && data !== null) {
            Object.keys(data).forEach((key) => {
                if (data[key] && data[key].multilanguage) {
                    try {
                        data[key] = data[key][language].value;
                    } catch (e) {
                        data[key] = '';
                    }
                } else {
                    ChannelItem.translateData(language, data[key]);
                }
            });
        }
    };

    /**
     * Select item
     */
    onSelectFormat = (format) => {
        const { selectedFormats = [] } = this.props;
        let selectedFormatsList = DataHelpers.clone(selectedFormats, false);

        if (selectedFormatsList.includes(format)) {
            selectedFormatsList = selectedFormats.filter((item) => format != item);
        } else {
            selectedFormatsList.push(format);
        }

        ComponentStore.setData('CreativeEditor', { selectedFormats: selectedFormatsList });
    };

    /**
     * Edit Background button pressed
     * Opens the dialog to crop your image
     * @param {string} format - The format as a string
     * @param {string} width The width of the output images
     * @param {string} height The height of the output image
     * @param {string} originalModel The full model path
     * @param {string} overwriteModel The overwrite model path
     */
    onEditBackground = (format, width, height, originalModel, overwriteModel) => {
        ComponentStore.setData('CreativeEditor', {
            editBackgroundOpen: true,
            editBackgroundFormat: format,
            editBackgroundWidth: width,
            editBackgroundHeight: height,
            originalModel,
            overwriteModel
        });
    };

    /**
     * Render the component
     */
    render() {
        const { isPreview = true } = this.props;

        // Get all items
        const items = this.socialItems();

        // No preview, render grid
        if (!isPreview && items.length > 1) {
            return (
                <React.Fragment>
                    <div className="creative-builder-item__single-grid">
                        {items.map((item) => (
                            <div className={'creative-builder-item__single-grid__item ' + item.className} key={item.key}>
                                {item.component}
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            );
        }
        // Preview, render below each other
        else {
            return (
                <React.Fragment>
                    {items &&
                        items.map &&
                        items.map((item) => (
                            <div className={item.className} key={item.key}>
                                {item.component}
                            </div>
                        ))}
                </React.Fragment>
            );
        }
    }
}

export default ChannelItem;
